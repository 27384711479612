<template>
  <div class="readme-article">
    <h1 id="财务配置">3.财务配置</h1>
    <h2 id="付款方式">3.1付款方式</h2>
    <p>
      设置付款方式，按公司自身情况配置，例如：一次付清、分期付款、按年缴费。
    </p>
    <p>
      <img src="@/assets/img/student/config3-1.png" />
    </p>
    <h2 id="价格设置">3.2价格设置</h2>
    <p>
      设置已添加专业的价格、付款方式。
    </p>
    <p>
      <img src="@/assets/img/student/config3-2.png" />
    </p>
    <h2 id="折扣设置">3.3折扣设置</h2>
    <p>
      根据公司活动设置折扣，例如几人团、员工介绍可折扣部分金额。
    </p>
    <p>
      <img src="@/assets/img/student/config3-3.png" />
    </p>
    <h2 id="收费项目">3.4收费项目</h2>
    <p>
      收费项目设置，添加额外的收费项目，在招生线索转正时可选择是否收取收费项目，例如：教材费、服务费等。
    </p>
    <p>
      <img src="@/assets/img/student/config3-4.png" />
    </p>
    <h2 id="支付渠道">3.5支付渠道</h2>
    <p>
      根据公司情况设置支付方式，例如：对公银行，微信支付，支付宝支付。
    </p>
    <p>
      <img src="@/assets/img/student/config3-5.png" />
    </p>
    <h2 id="在线支付设置">3.6在线支付设置</h2>
    <p>
      根据申请情况，配置各支付平台在线支付账号信息。</p>
    <p>
      <img src="@/assets/img/student/config3-6.png" />
    </p>
    <h2 id="积分规则">3.7积分规则</h2>
    <p>
      积分获取规则设置，举例说明如图。
    </p>
    <p>
      <img src="@/assets/img/student/config3-7.png" />
    </p>
    <h2 id="定金设置">3.8定金设置</h2>
    <p>
      定金设置，例如：新生充300抵500等。
    </p>
    <p>
      <img src="@/assets/img/student/config3-8.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Student0-3",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>